<template>
  <nav class="menu-container">
      <el-menu :default-active="selectedMenu" :router="false" ref="projectSidebar" class="el-menu-vertical menu-container" background-color="transparent" text-color="rgba(12, 15, 74, 0.5)">
          <el-menu-item class="menu-item" v-show="isProjectOwner" v-for="(menu, i) in menusPO" :key="i" @click="onSelectMenu(menu)" :index="menu.name">
              <div class="details-container">
                  <span v-show="menu.icon">
                    <img :src="menu.icon">
                  </span>
                  <div class="details">
                      <p class="label">{{menu.name}}</p>
                  </div>
              </div>
              <el-icon :size="15" color="rgba(12, 15, 74, 0.5)"><ArrowRightBold/></el-icon>
          </el-menu-item>
          <el-menu-item class="menu-item" v-show="isTradesperson" v-for="(menu, i) in menusTP" :key="i" @click="onSelectMenu(menu)" :index="menu.name">
              <div class="details-container">
                  <span v-show="menu.icon">
                    <img :src="menu.icon">
                  </span>
                  <div class="details">
                      <p class="label">{{menu.name}}</p>
                  </div>
              </div>
              <el-icon :size="15" color="rgba(12, 15, 74, 0.5)"><ArrowRightBold/></el-icon>
          </el-menu-item>
      </el-menu>
  </nav>
</template>
<script>
import {
  ArrowRightBold,
} from '@element-plus/icons';
import { computed, ref } from 'vue';
import { mapGetters } from 'vuex';

import { ProjectJobStatusEnum, ProjectQuoteStatusEnum } from '@/core/packages/shared-library';

export default {
  components: {
    ArrowRightBold,
  },
  //   initial data and algo for menu
  props: ['selected'],
  setup(props, { emit }) {
    const menusPO = ref([
      /* eslint-disable global-require */
      {
        icon: `${require('@/assets/icons/draft-icon.svg')}`, name: 'Draft', status: ProjectJobStatusEnum.DRAFT_PROJECT
      },
      {
        icon: `${require('@/assets/icons/awaiting-estimates-icon.svg')}`, name: 'Awaiting estimates', status: ProjectJobStatusEnum.AWAITING_QUOTES
      },
      {
        icon: `${require('@/assets/icons/estimates-receive-icon.svg')}`, name: 'Estimates received', status: ProjectJobStatusEnum.QUOTES_RECEIVED
      },
      {
        // we pass confirmed value as trigger to include awaiting, received, accepted
        icon: `${require('@/assets/icons/not-started-icon.svg')}`, name: 'Not started', status: ProjectJobStatusEnum.QUOTE_CONFIRMED
      },
      {
        icon: `${require('@/assets/icons/in-progress-icon.svg')}`, name: 'In progress', status: ProjectJobStatusEnum.IN_PROGRESS
      },
      {
        icon: `${require('@/assets/icons/completed-icon.svg')}`, name: 'Completed', status: ProjectJobStatusEnum.COMPLETED
      },
      {
        icon: `${require('@/assets/icons/cancelled-icon.svg')}`, name: 'Cancelled', status: ProjectJobStatusEnum.CANCELLED
      },
      {
        name: 'All'
      },
      /* eslint-enable global-require */
    ]);
    const menusTP = ref([
      /* eslint-disable global-require */
      {
        icon: `${require('@/assets/icons/account-details-dark-icon.svg')}`,
        name: 'Draft estimates',
        status: ProjectQuoteStatusEnum.DRAFT_QUOTE
      },
      {
        icon: `${require('@/assets/icons/estimates-receive-icon.svg')}`,
        name: 'Estimates sent',
        status: ProjectQuoteStatusEnum.QUOTE_SUBMITTED
      },
      {
        icon: `${require('@/assets/icons/pending-icon-dark.svg')}`,
        name: 'Pending confirmation',
        status: ProjectQuoteStatusEnum.QUOTE_ACCEPTED
      },
      {
        icon: `${require('@/assets/icons/notifications-icon.svg')}`,
        name: 'Not started',
        isProjectStatus: true,
        // we pass confirmed value as trigger to include awaiting, received, accepted
        status: ProjectJobStatusEnum.QUOTE_CONFIRMED
      },
      {
        icon: `${require('@/assets/icons/billing-icon.svg')}`,
        name: 'In Progress',
        status: ProjectQuoteStatusEnum.IN_PROGRESS
      },
      {
        icon: `${require('@/assets/icons/security-icon.svg')}`,
        name: 'Completed',
        status: ProjectQuoteStatusEnum.COMPLETED
      },
      {
        icon: `${require('@/assets/icons/notifications-icon.svg')}`,
        name: 'Cancelled',
        status: ProjectQuoteStatusEnum.CANCELLED
      },
      {
        name: 'All'
      },
      /* eslint-enable global-require */
    ]);

    function onSelectMenu(menu) {
      emit('select-menu', menu);
    }

    const selectedMenu = computed(() => {
      return props.selected;
    });

    return {
      menusPO,
      menusTP,
      onSelectMenu,
      selectedMenu
    };
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  }
};
</script>
<style lang="scss" scoped>
.menu-container {
    position:relative;
    // padding:3rem .5rem 0 1rem;
    // padding-top: 1rem;
    width: 100%;

    .el-menu {
        // margin-top: 2rem;
        display:flex;
        flex-direction: column;
        gap: 1rem;
    }

    .el-menu-item {
        width: 100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
        border-radius: 15px;
        padding: 0 1rem !important;
        box-sizing: unset;

        .details-container {
            display:flex;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;
            span {
                // background-color: rgba(12, 15, 74, 0.05);
                border-radius: 8px;
                // width: 50px;
                // height: 50px;
                display:flex;
                justify-content: center;
                align-items: center;
            }

            .details {
                display:flex;
                flex-direction: column;
                align-items:center;
                // gap:.5rem;
                justify-content: center;
                .label {
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                }
            }
        }
    }

    // .el-menu-item:last-child {
    //     border-bottom: none;
    // }
    .el-menu-item.is-active {
        background-color: rgba(12, 15, 74, 0.05);
    }
    .el-menu-item:hover {
        background-color: rgba(12, 15, 74, 0.05);
    //    background: transparent;
    //    span {
    //       background-color: #FAA100;
    //       transition: .3s ease-in-out;
    //     }
    }
}
</style>
