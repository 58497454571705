import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import { PAGE_LIMIT } from '@/core/constants';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    components: {
        SearchInputComponent
    },
    emits: ['is-loading', 'load-projects'],
    data() {
        return {
            projectListMetaData: {
                limit: PAGE_LIMIT,
                page: 1,
                totalCount: 0,
                totalPages: 0,
            },
            defaultProjectJobStatusIds: [
                ProjectJobStatusEnum.QUOTE_CONFIRMED,
                ProjectJobStatusEnum.IN_PROGRESS,
                ProjectJobStatusEnum.COMPLETED,
            ]
        };
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectMyJobList', 'setProjects']),
        loadProjects(filters) {
            this.$emit('load-projects', filters);
        }
    },
});
