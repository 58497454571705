// import { Search } from '@element-plus/icons';
// import * as mob from 'is-mobile';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';
import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import JobsPageHeader from '@/core/components/jobs/JobsPageHeader.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
// eslint-disable-next-line import/no-cycle
import { PROJECT_JOBS_ROUTE } from '@/modules/project-jobs/routes';
// import ProjectEmptySkeleton from '@/core/components/project/placeholder/ProjectEmptySkeleton.vue';
// import ProjectCardLayoutA from '@/core/components/project/project-card-layouts/ProjectCardLayoutA.vue';
// import ProjectMap from '@/core/components/project/project-map/ProjectMap.vue';
// import ProjectContainerSlot from '@/core/components/project/ProjectContainerSlot.vue';
// import Sort from '@/core/components/ui/Sort.vue';
// import ProjectJobEmptyComponent from '@/modules/project-jobs/components/ProjectJobEmptyComponent.vue';
// import ProjectJobFilterComponent from '@/modules/project-jobs/components/ProjectJobFilterComponent.vue';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import NoProjectsComponent from '@/modules/projects/components/NoProjectsComponent.vue';
import ProjectFilterComponent from '@/modules/projects/components/ProjectFilterComponent.vue';
import ProjectViewComponent from '@/modules/property-details-children/components/ProjectViewComponent.vue';
// import projectPageListMixin from '../../../mixins/projects/project-page-list.mixin';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
import { PAGE_LIMIT } from '../../../core/constants';
export default defineComponent({
    components: {
        ProjectFilterComponent,
        DisplayViewComponent,
        // Search,
        ProjectViewComponent,
        PageTitleWithCountComponent,
        NoProjectsComponent,
        SearchInputComponent,
        Loader,
        JobsPageHeader
    },
    data() {
        return {
            selectedMenu: 'All',
            viewType: 'list',
            drawer: false,
            isAppendToBody: false,
            defaultFilters: {
                areaCodeFilter: {
                    postcode: '',
                    distance: 5
                },
            },
            projectListMetaData: {
                limit: PAGE_LIMIT,
                page: 1,
                totalCount: 0,
                totalPages: 0,
            },
            pageFilters: {
                page: 1,
                limit: PAGE_LIMIT,
                projectJobStatusIds: [],
                projectQuoteStatusIds: []
            },
            defaultProjectJobStatusIds: [
                ProjectJobStatusEnum.QUOTE_CONFIRMED,
                ProjectJobStatusEnum.IN_PROGRESS,
                ProjectJobStatusEnum.COMPLETED,
            ],
        };
    },
    created() {
        // (this as any).checkIfUserIsVerified();
        this.initialize();
    },
    computed: {
        ...mapGetters(['generalSortFields', 'isLoading']),
        ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
        ...mapGetters(PROJECTS_STORE, ['currentPageViewMapping', 'projects']),
        isMapView() {
            const currentPageMapping = this.currentPageViewMapping;
            return currentPageMapping[PROJECT_JOBS_ROUTE] === 'map-view';
        },
    },
    methods: {
        setView(view) {
            this.viewType = view;
        },
        routeToAddProjectForm() {
            this.$router.push({
                name: 'find-jobs',
            });
        },
        ...mapActions(['checkIfUserIsVerified', 'setIsLoading']),
        ...mapActions(PROJECTS_STORE, ['getProjectMyJobList', 'setCurrentPageViewMapping', 'setProjects']),
        async initialize() {
            // this.setProjectDrawerAppendToBody();
            this.setCurrentPageViewMapping({
                [PROJECT_JOBS_ROUTE]: 'list-view'
            });
            await this.loadProjects(this.pageFilters).finally(() => { this.setIsLoading(false); });
        },
        onSelectJobStatus(menu) {
            const { status, isProjectStatus } = menu;
            const { pageFilters } = this;
            pageFilters.projectJobStatusIds = [];
            pageFilters.projectQuoteStatusIds = [];
            if (isProjectStatus) {
                pageFilters.projectJobStatusIds.push(status);
            }
            else {
                pageFilters.projectQuoteStatusIds.push(status);
            }
            this.loadProjects(pageFilters);
        },
        async onPaginateList(currentPage) {
            this.pageFilters.page = currentPage;
            await this.loadProjects(this.pageFilters);
        },
        async loadProjects(pageFilters) {
            this.setIsLoading(true);
            const currentFilters = pageFilters;
            await this.getProjectMyJobList(currentFilters)
                .then((response) => {
                this.setProjects(response.data);
                const { limit, page, totalCount, totalPages } = response;
                this.projectListMetaData = {
                    limit,
                    page,
                    totalCount,
                    totalPages,
                };
            })
                .finally(() => {
                this.setIsLoading(false);
            });
        },
        navigateToProjectOverview(projectRefId) {
            this.$router.push({ name: 'project-overview', params: { projectRefId } });
        },
        onFilterChange(filters) {
            this.pageFilters = Object.assign(this.pageFilters, { ...filters });
            // TODO: investigate why there's unnecessary field. Seems isTrusted is added directly in the object by axios or vue
            if ('isTrusted' in this.pageFilters) {
                delete this.pageFilters.isTrusted;
            }
            this.loadProjects(this.pageFilters);
        }
    },
});
